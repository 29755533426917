import React from 'react';
import { MDBNavItem, MDBIcon } from 'mdbreact';
import { Link } from 'react-router-dom';

const NavStatus = ({ setShow, me }) => {
  const profile = <MDBNavItem>
    <Link onClick={() => setShow(false)} to='/profile/main'>
      <MDBIcon icon="user-circle" className="mr-2" />
      {me.name || me.username}
      {!me.roles && !me.registration_completed && <MDBIcon icon="exclamation-triangle" className="text-warning ml-2" />}
    </Link>
  </MDBNavItem>;

  return profile;
};

export default NavStatus;