import React, { useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';
import useOnClickOutside from './hooks/useClickOutside';
import { MDBIcon } from 'mdbreact';

const Popper = ({ children, shouldClose, position='right' }) => {
  const size = 4;
  const [show, setShow] = useState(false);
  const [style, setStyle] = useState({});
  const w = useRef();
  useOnClickOutside(w, () => setShow(false));
  const left = position === 'left';

  const mobile = useCallback(() => {
    const { scrollTop, clientHeight } = document.documentElement;
    const { offsetHeight } = w.current;
    setStyle({
      left: 5,
      right: 5,
      top: scrollTop + clientHeight / 2 - offsetHeight / 2
    });
  }, []);

  const desktop = useCallback(() => {
    const { clientHeight, scrollTop, clientWidth } = document.documentElement;
    const { offsetHeight, offsetTop, offsetWidth, offsetLeft } = w.current;
    const bottomFrame = clientHeight + scrollTop - offsetHeight - 15;
    const topFrame = scrollTop + 15;
    const leftFrame = 15;
    const rightFrame = clientWidth - offsetWidth - 15;
    const topPos = scrollTop + offsetTop - offsetHeight / 3 - 15;
    const leftPos = left ? offsetLeft - offsetWidth : offsetLeft;
    setStyle({
      left: Math.max(leftFrame, Math.min(rightFrame, leftPos)),
      top: Math.max(Math.min(topPos, bottomFrame), topFrame),
      maxHeight: clientHeight - Math.max(Math.min(topPos, bottomFrame), topFrame) - 15
    });
  }, [left]);

  useEffect(() => {
    if(!show) return;
    size > 3 ? desktop() : mobile();
  }, [desktop, left, mobile, show, size]);

  useEffect(() => {
    if(shouldClose) setShow(false);
  }, [shouldClose]);
  
  const toggle = (e) => {
    const { x, width, y } = e.currentTarget.getBoundingClientRect();
    setStyle({
      top: y,
      left: (left ? x - 15 : x + width + 15)
    });
    setShow(!show);
  };

  return <div className="flex-grow-0" style={{ display: 'inline-block' }}>
    {React.cloneElement(children[0], { onClick: toggle })}
    {show && ReactDOM.createPortal(<div ref={w} style={{ zIndex: 1236, position: 'absolute', overflow: 'hidden', ...style }} className="white z-depth-1">
      {size <= 3 && <div className="d-flex justify-content-end">
        <button data-test="button" type="button" className="btn Ripple-parent btn-flat m-0 px-3 py-2">
          <MDBIcon onClick={() => setShow(false)} icon="times" size="2x" fal fixed />
        </button>
      </div> }
      {children[1]}
    </div>, document.getElementById('root'))}
  </div>;
};

export default Popper;