import React from 'react';
import { useContext, useState } from 'react';
import { MDBNavItem, MDBNavbar, MDBContainer, MDBNavbarToggler, MDBCollapse, MDBNavbarNav } from 'mdbreact';
import { Link, Outlet } from 'react-router-dom';
import { MeContext } from './context';
import { connecturl } from '../variables';
import NavStatus from './user/navStatus';
import l from '../localization';
import Status from './status';
import Popper from './shared/popper';

const Menu = (props) => {
  const { me } = useContext(MeContext);
  const [show, setShow] = useState(false);
  const menus = me.menus?.map((m, i) => {
    if(!m.children) {
      return(
        <MDBNavItem key={i}>
          <Link className="nav-link" to={'/' + m.value} onClick={() => setShow(false)}>{l[m.value + 'Menu']}</Link>
        </MDBNavItem>
      );
    } else {
      const dropdown = m.children.map((c, i) => {
        return <Link className="dropdown-item" key={i} to={`/${m.value}/${c}`} onClick={() => setShow(false)}>{l[c]}</Link>;
      });
      return <MDBNavItem key={i}>
        <Popper postion="bottom">
          <span className="nav-link" style={{ cursor: 'pointer' }}>{l[m.value]}</span>
          <div>
            {dropdown}
          </div>
        </Popper>
      </MDBNavItem>;
    }
  });
  return <>
    <header>
      <MDBNavbar
        color={show ? props.dark ? 'indigo' : 'white' : props.color}
        dark={props.dark}
        light
        transparent={show ? false : props.transparent}
        expand="md"
        scrolling
        fixed="top">
        <MDBContainer className="p-0">
          <Link to="/" className="navbar-brand">
            <img src={props.dark ? '/outlet_circle_light.png' : '/outlet_circle.png'} height="40" alt="outlet_logo" />
          </Link>
          <MDBNavbarToggler onClick={() => setShow(!show)} />
          <MDBCollapse id="navbarCollapse3" isOpen={show} navbar>
            <MDBNavbarNav left>
              {menus}
            </MDBNavbarNav>
            <MDBNavbarNav right>
              {me.id ?
                <NavStatus setShow={setShow} me={me} />
                : <>
                  <MDBNavItem><a className="nav-link" href={connecturl}>{l.login}</a></MDBNavItem>
                  <MDBNavItem><a className="nav-link" href={`${connecturl}signup#outlet`}>{l.register}</a></MDBNavItem>
                </>
              }
            </MDBNavbarNav>
          </MDBCollapse>
          {me.id && <Status />}
        </MDBContainer>
      </MDBNavbar>
    </header>
    {props.container
      ? <MDBContainer fluid className="mt-5 pt-5" style={{ minHeight: 'calc(100vh - 6rem)' }}>
        <Outlet />
      </MDBContainer>
      : <Outlet />}
  </>;
};

export default Menu;
