import React from 'react';
import { MDBFooter, MDBContainer } from 'mdbreact';
import { Link } from 'react-router-dom';
import l from '../localization';
import { authurl } from '../variables';

const Footer = () => {
  return (
    <MDBFooter color="indigo" className="font-small mt-4 mb-sm-0 mb-5">
      <MDBContainer fluid className="text-center py-4">
        <div>ООО &quot;АВТО ПАРТНЕРС&quot;, 129090, Москва, Олимпийский Проспект, 14, тел.: 8-800 333-63-00, email: remarketing@ap-ru.com</div>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          <div>&copy; {new Date().getFullYear()} Copyright: <a href="https://ap-ru.com" target="_blank" rel="noopener noreferrer"> Auto Partners </a></div>
          <div>
            <a href={`${authurl}/api/v4/external/static_files/98`} target="_blank" rel="noopener noreferrer">Политика в отношении обработки персональных данных</a>
            <a className="mx-3" href={`${authurl}/api/v4/external/static_files/104`}  target="_blank" rel="noopener noreferrer">Пользовательское соглашение</a>
            <a className="mx-3" href={`${authurl}/api/v4/external/static_files/97`}  target="_blank" rel="noopener noreferrer">Политика в отношении файлов cookie</a>
            <Link to="/rules">{l.rules}</Link>
          </div> 
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default Footer;