import { MDBAlert } from 'mdbreact';
import React, { useContext } from 'react';
import { MeContext } from '../context';

const UnconfirmedBanner = ({ link }) => {
  const { me } = useContext(MeContext);
  const registrationNotCompleted =  <>
    <h3>Регистрация не завершена!</h3>
    <p>Для участия в аукционе необходимо подтвердить 
      {!me.email_confirmed && me.phone_confirmed && ' почту'}
      {!me.phone_confirmed && me.email_confirmed && ' телефон'}
      {!me.phone_confirmed && !me.email_confirmed && ' почту и телефон'}
    </p>
    {link}
  </>;
  const hasDocumentComment = <>
    <p>{me.documents_comment}</p>
  </>;

  return (
    <>
      {me.id && (!me.email_confirmed || !me.phone_confirmed) && 
        <MDBAlert color="danger" className='w-100 mt-3'>
          {registrationNotCompleted}
        </MDBAlert>
      }
      {me.id && me.documents_comment && !me.confirmed && 
        <MDBAlert color="danger" className='w-100 mt-3'>
          {hasDocumentComment}
        </MDBAlert>
      }
    </>
  );
};

export default UnconfirmedBanner;