/* eslint-disable indent */
import React, { useContext } from 'react';
import { MDBCard, MDBCardHeader, MDBIcon, MDBCardBody } from 'mdbreact';
import { useReducer } from 'react';
import { Rnd } from 'react-rnd';
import { MeContext, WindowsContext } from '../context';
import Backoffice from '../backoffice/backoffice';

const Windows = () => {
  const { me } = useContext(MeContext);
  const [state, dispatch] = useReducer(reducer, { windows: [] });
  console.log(state);
  const lotWindows = state.windows.map((w, i) => {
    return <Window
      key={w.id}
      w={w}
      active={i === state.windows.length - 1}
      select={() => dispatch({ type: 'top', id: w.id })}
      close={() => dispatch({ type: 'close', id: w.id })}
    />;
  });
  return <WindowsContext.Provider value={{ state: state, dispatch: dispatch }}>
    <div style={{ zIndex: 1111, position: 'relative' }}>
      {lotWindows}
    </div>
    <Backoffice resources={(me.menus.find(v => v.value === 'backoffice') || {}).children} />
  </WindowsContext.Provider>;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'open':
      return { windows: [...state.windows, { id: state.windows.length, body: action.body, title: action.title }] };
    case 'close':
      return { windows: [...state.windows.filter(e => e.id !== action.id)] };
    case 'top':
      return {
        windows: [
          ...state.windows.filter(e => e.id !== action.id),
          ...state.windows.filter(e => e.id === action.id),
        ]
      };
    default:
      throw new Error();
  }
};


const Window = ({ w: { body, title }, select, close, active }) => {
  return <Rnd
    default={{
      x: 0,
      y: window.scrollY
    }}
    className="d-flex"
    dragHandleClassName="card-header"
    onDragStop={select}
    onResizeStop={select}>
    <MDBCard className="flex-fill" onClick={select}>
      <MDBCardHeader style={{ cursor: 'move' }} color={'light-blue lighten-' + (active ? 3 : 5)}
        className="d-flex flex-nowrap justify-content-between align-items-center py-0 pr-0">
        {title}
        <MDBIcon icon="times" far className="ml-4 p-3" style={{ cursor: 'pointer' }} onClick={close} />
      </MDBCardHeader>
      <MDBCardBody style={{ overflow: 'auto' }}>
        {body}
      </MDBCardBody>
    </MDBCard>
  </Rnd>;
};

export default Windows;