import React from 'react';
import { MDBMask, MDBContainer, MDBCard, MDBRow, MDBCol, MDBCardHeader, MDBIcon, MDBBtn } from 'mdbreact';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useResource } from 'ap-utils';
import Countdown from './auction/lots/partials/countdown';
import { ermurl, moment } from '../variables';
import { MeContext, SizeContext } from './context';
import l from '../localization';
import ConnectLinks from '../components/shared/connectLinks';
import UnconfirmedBanner from './shared/uncorfimedBanner';

const Home = () => {
  const { size } = useContext(SizeContext);
  const { me } = useContext(MeContext);
  const style = {
    backgroundImage: 'url(\'road.png\')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: (size > 0 ? '0 -40vh' : ''),
    height: (size > 0 ? '50vh' : '100vh'),
    backgroundAttachment: 'fixed',
  };
  return <>
    <div data-test="view" className="view pt-5"
      style={style}>
      <MDBMask overlay="blue-light" className="flex-center">
        <MDBContainer>
          <h1 className="white-text text-center">Добро пожаловать на аукцион Carsoutlet!</h1>
          <h3 className="white-text text-center">продажа подержанных автомобилей от собственника</h3>
          {!me.id && <div className="text-center">
            <ConnectLinks linkStyles="btn btn-lg btn-success waves-effect" />
          </div>}
        </MDBContainer>
      </MDBMask>
    </div>
    <MDBContainer>
      {!me.loading && <Recents />}
      <hr className="my-5" />
      <WhyUs />
    </MDBContainer>
  </>;
};

const Recents = () => {
  const { resource } = useResource('/outlet/lots', { result: [] }, { per_page: 3, current: 'true' });
  const linkToProfile = <p>Для подтверждения перейдите в <Link to={'/profile/main'}>профиль</Link> </p>;
  const cards = (resource.result || []).map((lot, i) => {
    const started = moment(lot.start_at).diff(moment()) > 0;
    const sortedPics = lot.index_pics?.sort((a, b) => (b.updated_at - a.updated_at)).sort((a, b) => b.is_avatar - a.is_avatar) || [];
    console.log(lot);
    return <MDBCol key={i} sm="12" md="4" className="d-flex">
      <MDBCard className="flex-fill mb-2">
        <Link to={`/lots/${lot.id}`} className="text-reset">
          <div>
            <div
              className="indigo white-text chip m-0"
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                opacity: 0.8,
              }}>
              <Countdown lot={lot} prepend={started ? l.startsIn : l.endsIn} />
            </div>
            <div
              style={{
                height: 200,
                width: '100%',
                backgroundImage: `url(${ermurl + (sortedPics[0] || {}).url})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
              }}>
            </div>
            <div className="p-3">
              <h3>{lot.short_name}</h3>
              <h6>{lot.year} | {lot.vehicle?.city_of_remarketing_name} | {Number(lot.km).toLocaleString()}</h6>
              <div className="d-flex justify-content-between align-items-center">
                <h3>{Number(lot.last_bid).toLocaleString()}</h3>
                <MDBBtn outline color="success" size="sm">подробнее</MDBBtn>
              </div>
            </div>
          </div>
        </Link>
      </MDBCard>
    </MDBCol>;
  });
  return <MDBRow>
    <UnconfirmedBanner link={linkToProfile} />
    <MDBCol sm="12">
      <div className="h1 text-center my-5">Актуальные лоты</div>
    </MDBCol>
    {cards}
    {!!cards.length && <MDBCol size="12" className='flex-center'>
      <Link className="btn btn-success btn-lg" to="/lots">
        показать все
      </Link>
    </MDBCol>
    }
  </MDBRow>;
};

const WhyUs = () => {
  const opps = [
    { title: 'Покупка у юридического лица', text: 'Только авто от одного владельца', icon: 'user-shield' },
    { title: 'Проверка автомобилей перед продажей', text: 'Полная история обслуживания', icon: 'th-list' },
    { title: 'Юридически чистая история автомобилей', text: '', icon: 'balance-scale' },
    { title: 'Реальный пробег', text: '', icon: 'road' },
    { title: 'Обслуживание в сертифицированных СТО', text: '', icon: 'shield' },
    { title: 'Возврат НДС (для юридических лиц)', text: 'Для юрлиц', icon: 'wallet' },
  ].map((o, i) => {
    return <MDBCol xl="4" md="6" sm="12" key={i}>
      <MDBCard className="mb-3">
        <MDBCardHeader color="indigo" className="d-flex justify-content-start align-items-center">
          <MDBIcon icon={o.icon} fal size="5x" fixed className="mr-2" />
          <div className="h5">{o.title}</div>
        </MDBCardHeader>
      </MDBCard>
    </MDBCol>;
  });
  return <>
    <div className="h1 text-center mb-5">Преимущества</div>
    <MDBRow>
      {opps}
    </MDBRow>
  </>;
};

export default Home;