import React from 'react';

const MeContext = React.createContext({
  me: null,
  setMe: () => {},
});

const LotsContext = React.createContext({
  lots: [],
  setLots: () => {},
  setLot: undefined
});

const WindowsContext = React.createContext({
  state: {},
  dispatch: () => {}
});

const SizeContext = React.createContext({
  size: '',
  setState: () => {}
});

export { MeContext, LotsContext, WindowsContext, SizeContext };