import React from 'react';
import { MDBSpinner } from 'mdbreact';

const Loader = ({ loading }) => {
  return loading ? <div style={{ position: 'absolute', top: 0, bottom: 0, width: '100%' }}>
    <div style={{ zIndex: 5, position: 'sticky', top: 50, maxHeight: 'calc(100vh - 50px)', height: '100%', width: '100%', backgroundColor: 'rgba(255,255,255,.7)' }}
      className="d-flex align-items-center justify-content-center">
      <MDBSpinner />
    </div>
  </div> : '';
};

export default Loader;