import React from 'react';
import { useWs } from 'ap-utils';
import { MDBBadge } from 'mdbreact';
import { useContext } from 'react';
import { SizeContext } from './context';

const Status = () => {
  const { readyState } = useWs();
  const { size } = useContext(SizeContext);
  const states = [
    <MDBBadge color="info" key={1}>Подключаемся</MDBBadge>,
    <MDBBadge color="success" key={2}>В сети</MDBBadge>,
    <MDBBadge color="danger" key={3}>Не в сети</MDBBadge>,
    <MDBBadge color="danger" key={4}>Не в сети</MDBBadge>,
  ];
  return <div style={{ zIndex: 1111, position: 'fixed', bottom: (size === 0 ? 50 : 10), right: 10 }}>{states[readyState]}</div>;
};

export default Status;