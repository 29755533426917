import React, { useCallback, useMemo, useState } from 'react';
import { MDBBtn, MDBContainer, MDBIcon, MDBTable, MDBTableBody, MDBTableHead, MDBTooltip } from 'mdbreact';
import { useResource } from 'ap-utils';
import l from '../../../localization';
import { api } from '../../../variables';
import Loader from '../../shared/loader';

const LotLine = ({ lots, lot, setLots }) => {
  const [value, setValue] = useState(lot.my_last_bid || 0);
  const sendBid = useCallback(async (lot_id, bid) => {
    try {
      const { data } = await api.patch(`outlet/lots/${lot_id}/update_last_bid`, { bid: bid });
      setLots(res => ({ ...res, result: res.result.map(ll => ll.id === data.id ? data : ll) }));
      setValue(data.my_last_bid);
    } catch (error) {
      console.log(error);
    }
  }, [setLots]);
  return <tr>
    {lots.headers.filter(h => h !== 'my_last_bid').map(h => <td className='p-1' key={lot.key + h}>{lot[h]}</td>)}
    <td className='p-1'>
      <div className='d-flex'>
        <input
          type='number'
          min={50000}
          max={1000000000}
          step={1000}
          key={lot.id + 'input'}
          onBlur={e => sendBid(lot.id, e.target.value)}
          label='Ваша цена...'
          onChange={(e) => setValue(e.target.value)}
          value={value} />
        {lot.my_last_bid ? <MDBTooltip>
          <MDBBtn onClick={() => sendBid(lot.id, 0)} color='danger' size='sm' className="m-0 px-2 ml-2"><MDBIcon icon='trash' /></MDBBtn>
          <span>Обнулить</span>
        </MDBTooltip> : <MDBTooltip>
          <MDBBtn onClick={() => sendBid(lot.id, value)} size='sm' className="m-0 px-2 ml-2"><MDBIcon icon='save' /></MDBBtn>
          <span>Сохранить</span>
        </MDBTooltip>}
      </div>
    </td>
  </tr>;
};

const LotsPrices = () => {
  const { resource: lots, setResource: setLots, loading } = useResource('outlet/lots/for_prices_filling', { headers: [], result: [] }, { per_page: 100 });

  const rows = useMemo(() => lots.result.map((lot) => <LotLine key={lot.id} {...{ lot, lots, setLots }} />), [lots, setLots]);

  return <MDBContainer fluid>
    <Loader loading={loading} />
    <MDBTable bordered>
      <MDBTableHead>
        <tr>
          {lots.headers.map(h => <th key={h}>{l[h]}</th>)}
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {rows}
      </MDBTableBody>
    </MDBTable >
  </MDBContainer>;
};

export default LotsPrices;