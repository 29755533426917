import { useResource } from 'ap-utils';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { useMemo } from 'react';
import l from '../../../localization';

const VehiclesPrices = ({ id }) => {
  const { resource } = useResource(`/erm/auctions/${id}/vehicles_prices`, []);
  const headers = useMemo(() => Object.keys(resource[0] || {}), [resource]);
  return <MDBTable small bordered className='fixed overflow-auto'>
    <MDBTableHead>
      <tr>
        {headers.map(h => <th key={h}>{l[h] || h}</th>)}
      </tr>
    </MDBTableHead>
    <MDBTableBody>
      {
        resource.map(lot => <tr key={lot.id}>
          {headers.map(h => <th key={h + lot.id}>{lot[h]}</th>)}
        </tr>)
      }
    </MDBTableBody>
  </MDBTable>;
};

export default VehiclesPrices;