import LocalizedStrings from 'react-localization';
import { conditionsRu, conditionsEn } from './conditions';

const strings = [
  { string: 'blnk', en: '', ru: '' },
  { string: 'passwordConfirm', en: 'Confirm password', ru: 'Подтверждение пароля' },
  { string: 'rules_common', en: 'Buyer signature', ru: 'Общие положения' },
  { string: 'rules_registration', en: 'Buyer signature', ru: 'Регистрация' },
  { string: 'rules_requirements', en: 'Buyer signature', ru: 'Требования к Участнику Аукциона' },
  { string: 'rules_auction', en: 'Buyer signature', ru: 'Порядок проведения Аукциона' },
  { string: 'rules_payments', en: 'Buyer signature', ru: 'Оплата' },
  { string: 'rules_guarantees', en: 'Buyer signature', ru: 'Гарантии' },
  { string: 'rules_confidentiality', en: 'Buyer signature', ru: 'Конфиденциальность' },
  { string: 'rules_dictionary', en: 'Buyer signature', ru: 'Термины и определения' },
  { string: 'signature', en: 'Buyer signature', ru: 'Подписант' },
  { string: 'blockReason', en: 'Block reason', ru: 'Причина блокировки' },
  { string: 'buyer', en: 'Buyer', ru: 'Покупатель (родительный падеж)' },
  { string: 'confirmation', en: 'Confirmation', ru: 'Подтверждение почты и телефона' },
  { string: 'rules', en: 'Rules', ru: 'Правила' },
  { string: 'buyer_base', en: 'Buyer base', ru: 'На основании' },
  { string: 'approved_for_sale', en: 'Approved', ru: 'Согласован для продажи' },
  { string: 'youAreNotLast', en: 'Maximum bid is not yours', ru: 'Ваша ставка ниже' },
  { string: 'need_new_price', en: 'Price rejected', ru: 'Цена не согласована' },
  { string: 'create', en: 'Create', ru: 'Создать' },
  { string: 'salesPrice', en: 'Sales price', ru: 'Цена продажи' },
  { string: 'offeredPrice', en: 'Bid', ru: 'Ставка' },
  { string: 'bid_won', en: 'Won', ru: 'Победная' },
  { string: 'sales_at', en: 'Sales date', ru: 'Дата продажи' },
  { string: 'logout', en: 'Sign out', ru: 'Выйти' },
  { string: 'bid_refused', en: 'Refused', ru: 'Отказ' },
  { string: 'sold', en: 'Sold', ru: 'Продан' },
  { string: 'empty', en: 'No matches', ru: 'Ничего не найдено' },
  { string: 'needs_approve', en: 'Needs approve', ru: 'На согласовании' },
  { string: 'confirm', en: 'Confirm', ru: 'Активировать' },
  { string: 'approve', en: 'Approve', ru: 'Согласовать' },
  { string: 'endSoon', en: 'Ends soon', ru: 'Скоро закончится' },
  { string: 'reject', en: 'Reject', ru: 'Отказать' },
  { string: 'comment', en: 'Comment', ru: 'Комментарий' },
  { string: 'setWinner', en: 'Winner', ru: 'Победитель' },
  { string: 'disable', en: 'Disable', ru: 'Заблокировать' },
  { string: 'created_at', en: 'Created', ru: 'Дата и время' },
  { string: 'user_created_at', en: 'Created', ru: 'Дата регистрации' },
  { string: 'last_bid_at', en: 'Last bid', ru: 'Последняя ставка' },
  { string: 'startsIn', en: 'Starts in', ru: 'Начало через' },
  { string: 'endsIn', en: 'Left', ru: 'Осталось' },
  { string: 'amount', en: 'Amount', ru: 'Сумма' },
  { string: 'last_bid_company', en: 'Company', ru: 'Компания' },
  { string: 'last_bid_user', en: 'User', ru: 'Пользователь' },
  { string: 'ip', en: 'ip', ru: 'ip' },
  { string: 'all', en: 'All', ru: 'Все' },
  { string: 'makeBid', en: 'BID', ru: 'сделать ставку' },
  { string: 'start_at', en: 'Start', ru: 'Начало' },
  { string: 'end_at', en: 'End', ru: 'Окончание' },
  { string: 'incorrect', en: 'incorrect', ru: 'кривой' },
  { string: 'locked', en: 'Locked', ru: 'Готов к запуску' },
  { string: 'finished', en: 'Finished', ru: 'Завершён' },
  { string: 'preparing', en: 'Preparing', ru: 'Готовится' },
  { string: 'unpublished', en: 'Unpublished', ru: 'Не опубликованный' },
  { string: 'published', en: 'Published', ru: 'Опубликованный' },
  { string: 'last_bid', en: 'Last bid', ru: 'Последняя ставка' },
  { string: 'price', en: 'Orice', ru: 'Цена' },
  { string: 'ended', en: 'Ended', ru: 'Завершён' },
  { string: 'manager', en: 'Manager', ru: 'Менеджер' },
  { string: 'parking_address', en: 'Car is loacted at', ru: 'Место осмотра' },
  { string: 'hoursShort', en: 'hr', ru: 'ч' },
  { string: 'apply', en: 'apply', ru: 'применить' },
  { string: 'search', en: 'find', ru: 'найти' },
  { string: 'orderBy', en: 'Order by', ru: 'Сортировать по' },
  { string: 'byStart', en: 'start date', ru: 'дате начала' },
  { string: 'byEnd', en: 'end date', ru: 'дате окончания' },
  { string: 'byBrand', en: 'brand', ru: 'бренду' },
  { string: 'byMileage', en: 'km', ru: 'пробегу' },
  { string: 'byPrice', en: 'price', ru: 'цене' },
  { string: 'km', en: 'km', ru: 'км' },
  { string: 'alreadyOnAuction', en: 'Already On Auction', ru: 'Автомобиль уже добавлен на аукцион' },
  { string: 'invalidDate', en: 'Invalid date', ru: 'Некорректная дата' },
  { string: 'showMore', en: 'Show more', ru: 'Показать ещё' },
  { string: 'available_status', en: 'Status', ru: 'Статус' },
  { string: 'vehicle_year_of_production', en: 'Year', ru: 'г.в.' },
  { string: 'name', en: 'Name', ru: 'Наименование' },
  { string: 'vin', en: 'vin', ru: 'vin' },
  { string: 'in_pledge', en: 'Pledge', ru: 'Залог' },
  { string: 'sales_channel_name', en: 'Sales channel', ru: 'Продажа через' },
  { string: 'purchase_price', en: 'Purchase price', ru: 'Закупочная' },
  { string: 'sales_quote', en: 'Quote', ru: 'Квота' },
  { string: 'valuation', en: 'Valuation', ru: 'Цена по формуле' },
  { string: 'condition_penalty', en: 'Condition penalty', ru: 'Восст.ремонт' },
  { string: 'early_termination_penalty', en: 'Early termination penalty', ru: 'Досрочный возврат' },
  { string: 'extra_less_penalty', en: 'Extra/less mlg', ru: 'Пере/недопробег' },
  { string: 'returned_days', en: 'returned days', ru: 'Дней' },
  { string: 'vehicle_model', en: 'Model', ru: 'Модель' },
  { string: 'definition_name', en: 'Model', ru: 'Модель' },
  { string: 'gearbox', en: 'Gearbox', ru: 'КПП' },
  { string: 'fuel_type', en: 'Fuel type', ru: 'Тип топлива' },
  { string: 'body_type', en: 'Body type', ru: 'Кузов' },
  { string: 'drive_type', en: 'Drive type', ru: 'Привод' },
  { string: 'drive_type_id', en: 'Drive type', ru: 'Привод' },
  { string: 'return_km', en: 'Mileage', ru: 'Пробег' },
  { string: 'city', en: 'City', ru: 'Город' },
  { string: 'city_of_remarketing_name', en: 'City', ru: 'Город' },
  { string: 'cities', en: 'Cities', ru: 'Города' },
  { string: 'brands', en: 'Brands', ru: 'Марки' },
  { string: 'brand', en: 'Brand', ru: 'Марка' },
  { string: 'vehicle_model', en: 'Model', ru: 'Модель' },
  { string: 'vehicle_models', en: 'Models', ru: 'Модели' },
  { string: 'options', en: 'Options', ru: 'Комплектация' },
  { string: 'damages', en: 'Damages', ru: 'Повреждения' },
  { string: 'maintenances', en: 'Service', ru: 'ТО' },
  { string: 'send_bid', en: 'Bid!', ru: 'Отправить' },
  { string: 'login', en: 'Log in!', ru: 'Войти' },
  { string: 'register', en: 'Sign up!', ru: 'Зарегистрироваться' },
  { string: 'signUp', en: 'Sign up!', ru: 'Регистрация' },
  { string: 'mainDetails', en: 'Main', ru: 'Основные' },
  { string: 'favourites', en: 'Favourite cars', ru: 'Избранное' },
  { string: 'confirmed', en: 'Confirmed', ru: 'Подтверждён' },
  { string: 'unconfirmed', en: 'Unconfirmed', ru: 'Не подтверждён' },
  { string: 'codeRequest', en: 'Send code', ru: 'Запросить код подтверждения' },
  { string: 'phone', en: 'Phone', ru: 'Телефон' },
  { string: 'phoneUnconfirmed', en: 'Phone (unconfirmed)', ru: 'Телефон (не подтверждён)' },
  { string: 'email', en: 'Email', ru: 'Email' },
  { string: 'emailUnconfirmed', en: 'Email (unconfirmed)', ru: 'Email (не подтверждён)' },
  { string: 'code', en: 'Code', ru: 'Код' },
  { string: 'confirmed', en: 'Confirmed', ru: 'Подтверждён' },
  { string: 'mismatch', en: 'Did not match', ru: 'Не совпадает' },
  { string: 'won', en: 'Won cars', ru: 'Выигранные' },
  { string: 'save', en: 'Save', ru: 'Сохранить' },
  { string: 'cancel', en: 'Cancel', ru: 'отменить' },
  { string: 'updated', en: 'Updated', ru: 'Изменения сохранены' },
  { string: 'new_auction', en: 'New auction', ru: 'Новый аукцион' },
  { string: 'add_all_vehicles', en: 'Add all vehicles', ru: 'Добавить все автомобили' },
  { string: 'residual_value', en: 'RV', ru: 'RV' },
  { string: 'filters', en: 'Filters', ru: 'Фильтры' },
  { string: 'bidsList', en: 'Bids', ru: 'Последние ставки' },
  { string: 'about_auction', en: 'About auction', ru: 'Об аукционе' },
  { string: 'about_company', en: 'About company', ru: 'О компании' },
  { string: 'guaranties', en: 'Guaranties', ru: 'Гарантии' },
  { string: 'lots', en: 'Lots', ru: 'Лоты' },
  { string: 'backoffice', en: 'Backoffice', ru: 'Backoffice' },
  { string: 'vehicles', en: 'Vehicles', ru: 'Автомобили' },
  { string: 'auctions', en: 'Auctions', ru: 'Аукционы' },
  { string: 'users', en: 'Users', ru: 'Пользователи' },
  { string: 'fav_add', en: 'Add to favourites', ru: 'Добавить в избранное' },
  { string: 'fav_remove', en: 'Remove from favoutires', ru: 'Удалить из избранного' },
  { string: 'fav_added', en: 'Added to favourites', ru: 'Лот успешно добавлен в избранное' },
  { string: 'fav_removed', en: 'Removed from favoutires', ru: 'Лот успешно удалён из избранного' },
  { string: 'hide', en: 'Hide', ru: 'Скрыть' },
  { string: 'makeBid', en: 'Bid', ru: 'Сделать ставку' },
  { string: 'bidDone', en: 'Bid accepted', ru: 'Ставка принята' },
  { string: 'bidLimit', en: 'Bid can`t be under', ru: 'Ставка не может быть ниже' },
  { string: 'language', en: 'Language', ru: 'Язык' },
  { string: 'today', en: 'Today', ru: 'Сегодня' },
  { string: 'yesterday', en: 'Yesterday', ru: 'Вчера' },
  { string: 'attach', en: 'Send', ru: 'Отправить' },
  { string: 'documents', en: 'Files', ru: 'Файлы' },
  { string: 'uploaded', en: 'File uploaded', ru: 'Файлы загружены' },
  { string: 'deleted', en: 'File deleted', ru: 'Файл удалён' },
  { string: 'id', en: 'id', ru: 'id' },
  { string: 'enabled', en: 'Enabled', ru: 'Активный' },
  { string: 'username', en: 'Username', ru: 'Имя пользователя' },
  { string: 'company_name', en: 'Company name', ru: 'Компания' },
  { string: 'roles', en: 'Roles', ru: 'Роли' },
  { string: 'actions', en: '', ru: '' },
  { string: 'status', en: 'Status', ru: 'Статус' },
  { string: 'plate', en: 'Plate no', ru: 'Гос номер' },
  { string: 'vehicle_plate_no', en: 'Plate no', ru: 'Гос номер' },
  { string: 'auction', en: 'Auction', ru: 'Аукцион' },
  { string: 'back', en: 'Back', ru: 'Назад' },
  { string: 'useComa', en: 'Split options by coma', ru: 'Для разделения опций использовать запятую.' },
  { string: 'currentBid', en: 'Current bid', ru: 'Текущая ставка' },
  { string: 'limitedPrice', en: 'The bid cannot exceed 130% of the cost', ru: 'Ставка не может превышать 130% от стоимости' },
  { string: 'auctionEnd', en: 'Auction has ended ', ru: 'Аукцион уже завершен' },
  { string: 'myBid', en: 'My bid', ru: 'Моя ставка' },
  { string: 'totalBids', en: 'Total bids', ru: 'Ставок' },
  { string: 'password', en: 'Password', ru: 'Пароль' },
  { string: 'continue', en: 'Continue', ru: 'Продолжить' },
  { string: 'enterCode', en: 'Enter code', ru: 'Введите код из СМС' },
  { string: 'conditions', en: 'Terms and conditions', ru: 'Согласие на обработку персональных даных' },
  { string: 'agree', en: 'I agree', ru: 'Согласен' },
  { string: 'humanName', en: 'Name', ru: 'Имя' },
  { string: 'driverNote', en: 'Driver notes', ru: 'Комментарий водителя' },
  { string: 'managerNote', en: 'Manager notes', ru: 'Комментарий менеджера' },
  { string: 'serviceWorks', en: 'Service works', ru: 'Работы' },
  { string: 'daysShort', en: 'd', ru: 'д' },
  { string: 'shoulEnterDate', en: 'Please enter dates of start and end', ru: 'Необходимо заполнить даты начала и окончания' },
  { string: 'startAt', en: 'Start', ru: 'Начало' },
  { string: 'endAt', en: 'End', ru: 'Окончание' },
  { string: 'homeMenu', en: 'Home', ru: 'Главная' },
  { string: 'rulesMenu', en: 'Rules', ru: 'Правила' },
  { string: 'aboutusMenu', en: 'About us', ru: 'О компании' },
  { string: 'closed', en: 'Closed', ru: 'Закрыт' },
  { string: 'lotsMenu', en: 'Cars', ru: 'Автомобили' },
  { string: 'show', en: 'Show', ru: 'Показать' },
  { string: 'lock', en: 'Lock', ru: 'Блокировка' },
  { string: 'publish', en: 'Publish', ru: 'Опубликовать' },
  { string: 'remove', en: 'Hide', ru: 'Скрыть' },
  { string: 'company', en: 'Company', ru: 'Компания' },
  { string: 'restore', en: 'Restore', ru: 'Возобновить' },
  { string: 'unlock', en: 'Unlock', ru: 'Разблокировать' },
  { string: 'fill_all_price', en: 'Set quote price', ru: 'Установить цену из квоты' },
  { string: 'address', en: 'Address', ru: 'Адрес' },
  { string: 'postal_address', en: 'Post address', ru: 'Почтовый адрес' },
  { string: 'inn', en: 'INN', ru: 'ИНН' },
  { string: 'notSaved', en: 'Could not save', ru: 'Не удалось сохранить' },
  { string: 'showExtra', en: 'Show filters', ru: 'Показать фильтры' },
  { string: 'kpp', en: 'KPP', ru: 'КПП' },
  { string: 'director_name', en: 'GM Name', ru: 'Генеральный директор' },
  { string: 'bank_name', en: 'Bank name', ru: 'Наименование банка' },
  { string: 'bank_account', en: 'Bank account', ru: 'Расчетный счет' },
  { string: 'bank_cor_account', en: 'Bank correspondent account', ru: 'Корреспондентский счет' },
  { string: 'diff', en: 'Difference', ru: 'Разница' },
  { string: 'bank_bik', en: 'Bank BIK', ru: 'БИК' },
  { string: 'ogrn', en: 'OGRN', ru: 'ОГРН' },
  { string: 'youAreLast', en: 'Your bid is highest', ru: 'Ваша ставка максимальная' },
  { string: 'phone_confirmed', en: 'Phone confirmed', ru: 'Телефон подвержден' },
  { string: 'email_confirmed', en: 'Email confirmed', ru: 'Email подтвержден' },
  { string: 'refuse', en: 'Refuse', ru: 'Отказ' },
  { string: 'baseDetails', en: 'Base details', ru: 'Базовая информация' },
  { string: 'passwordMismatch', en: 'You entered different passwords', ru: 'Пароли не совпадают' },
  { string: 'usernameExist', en: 'Username exists', ru: 'Пользователь уже существует' },
  { string: 'step', en: 'Rate step', ru: 'Шаг ставки' },
  { string: 'active', en: 'Granted', ru: 'Допущен' },
  { string: 'has_files', en: 'Has files', ru: 'Файлы загружены' },
  { string: 'waiting', en: 'Waiting', ru: 'Ожидает' },
  { string: 'trash', en: 'Trash', ru: 'Корзина' },
  { string: 'disabled', en: 'disabled', ru: 'Заблокирован' },
  { string: 'archived', en: 'archived', ru: 'Архивирован' },
  { string: 'shouldSignIn', en: 'Unauthorized users can not bid. Please ', ru: 'Неавторизованные пользователи не могут делать ставки. ' },
  { string: 'shortPassword', en: 'Password should be at least 8 characters long', ru: 'Пароль должен иметь не менее 8 символов' },
  { string: 'fatPassword', en: 'Password should not be greater than 128 characters', ru: 'Пароль должен иметь не более 128 символов' },
  { string: 'lowCasePassword', en: 'Password should contain at least one lowercase letter', ru: 'Пароль должен содеражть хотя бы одну строчную букву' },
  { string: 'upCasePassword', en: 'Password should contain at least one uppercase letter', ru: 'Пароль должен содеражть хотя бы одну заглавную букву' },
  { string: 'numberPassword', en: 'Passwordshould contain at least one number', ru: 'Пароль должен содеражть хотя бы одну цифру' },
  { string: 'shortUsername', en: 'Username should be at least 3 characters long', ru: 'Имя пользователя не может быть менее 3 символов' },
  { string: 'fatUsername', en: 'Username should not be greater than 30 characters', ru: 'Имя пользователя не может быть более 30 символов' },
  { string: 'wrongUsername', en: 'Username should contain only A-Z a-z 0-9 . - _', ru: 'Имя пользователя может содержать только  A-Z a-z 0-9 . - _' },
  { string: 'my_last_bid', en: 'My bid', ru: 'Моя ставка' },
  { string: 'vehicle_name', en: 'Vehicle name', ru: 'Автомобиль' },
  { string: 'notified_users', en: 'Notified users', ru: 'Пользователи для рассылки' },
  { string: 'wrongUsernameFirstLast', en: 'Username should start and end with letter or digit', ru: 'Имя пользователя должно начинаться и заканчиваться буквой или цифрой' },
  { string: 'apConditions', en: conditionsEn, ru: conditionsRu },
  { string: 'email0', alias: 'email' },
  { string: 'phone0', alias: 'phone' },
  { string: 'last_bid_price', alias: 'last_bid' },
  { string: 'vehicle_brands', alias: 'brands' },
  { string: 'vehicle_brand', alias: 'brand' },
  { string: 'step0', alias: 'conditions' },
  { string: 'step1', alias: 'blnk' },
  { string: 'step2', alias: 'baseDetails' },
  { string: 'step3', alias: 'confirmation' },
  { string: 'step4', alias: 'blnk' },
  { string: 'step5', alias: 'blnk' },
];

const en = strings.reduce((res, v) => {
  res[v.string] = v.string.alias ? strings[v.alias].en : v.en;
  return res;
}, {});

const ru = strings.reduce((res, v) => {
  res[v.string] = (v.alias ? strings.find(e => e.string === v.alias) : v).ru;
  return res;
}, {});

const l = new LocalizedStrings({
  en: en,
  ru: ru
});

export default l;