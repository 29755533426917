import React from 'react';
import { useContext, useEffect, useState, useLayoutEffect } from 'react';
import { MDBContainer, MDBIcon, MDBMask } from 'mdbreact';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import loadable from '@loadable/component';
import Menu from './menu';
import Footer from './footer';
import Home from './home';
import { MeContext, SizeContext } from './context';
import '../stylesheets/application.scss';
import l from '../localization';
import Windows from './shared/windows';
import { api, wsurl } from '../variables';
import { useParams, useWs } from 'ap-utils';
import ErrorBoundary from './errorboundary';
import { IsTest, isLocalhost } from '../serviceWorker';
import LotsPrices from './auction/lots_prices';
const Lots = loadable(() => import('./auction/lots/index'));
const Lot = loadable(() => import('./auction/lots/show'));
const SignUp = loadable(() => import('./user/signUp'));
const Profile = loadable(() => import('./user/profile'));

const AucNav = () => {
  const defaultMe = {
    loading: true,
    id: null,
    menus: []
  };
  const [me, setMe] = useState(defaultMe);

  const [size, setSize] = useState(0);

  useEffect(() => {
    loadMe();
    l.setLanguage('ru');
  }, []);

  useEffect(() => {
    if (IsTest) {
      document.title = `[TEST] ${document.title}`;
    } if (isLocalhost) {
      document.title = `[DEV] ${document.title}`;
    }
  }, []);

  useLayoutEffect(() => {
    const updateSize = () => {
      let s = 0;
      if (window.innerWidth >= 1200) s = 4;
      if (window.innerWidth >= 992) s = 3;
      if (window.innerWidth >= 768) s = 2;
      if (window.innerWidth >= 576) s = 1;
      setSize(s);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const loadMe = async () => {
    try {
      setMe(me => ({ ...me, loading: true }));
      const { data } = await api.get('/common/users/me', { params: { method: 'outlet_me' }, withCredentials: true });
      const jwt = data.logged_as?.jwt || data.jwt;
      if (jwt) {
        api.defaults.headers.common.Authorization = `Bearer ${jwt}`;
      }
      useWs.initializeWs(wsurl + '?jwt=' + jwt);
      setMe({ ...(data.logged_as || data), loading: false });
    } catch (error) {
      setMe({ menus: ['lots', 'rules', 'aboutus'].map((e) => ({ value: e })) });
      console.log(error);
    }
  };

  const body = <ErrorBoundary>
    <MeContext.Provider value={{ me: me, setMe: setMe }}>
      <SizeContext.Provider value={{ size: size }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Menu dark color="indigo" transparent />}>
              <Route path="/" element={<Home />} />
              <Route path="aboutus" element={<About />} />
            </Route>
            <Route path="/" element={<Menu color="white" container />}>
              <Route path="signup" element={<SignUp />} />
              <Route path="profile/*" element={<Profile />} />
              <Route path="rules" element={<Rules />} />
              <Route path="lots" element={<Lots />} />
              <Route path='lots_prices' element={<LotsPrices />} />
              <Route path="lots/:id" element={<Lot />} />
              <Route path="backoffice/*" element={<Windows />} />
            </Route>
          </Routes>
          <MobileMenu />
          <Footer />
        </BrowserRouter>
      </SizeContext.Provider>
    </MeContext.Provider>
  </ErrorBoundary>;

  return me.loading ? <></> : body;
};

export default AucNav;

const MobileMenu = () => {
  const { pathname } = useParams();
  const { me } = useContext(MeContext);
  const style = {
    position: 'fixed',
    left: 0,
    right: 0,
    height: '3rem',
    background: 'white',
    zIndex: 1040,
    bottom: 0,
    boxShadow: '0 -2px 5px 0 rgba(0, 0, 0, 0.16), 0 -2px 10px 0 rgba(0, 0, 0, 0.12)',
    fontSize: '1.5rem'
  };
  const bo = me.roles ? [{ icon: 'user-crown', path: '/backoffice/lots' }] : [];
  const links = [
    { icon: 'home', path: '/' },
    { icon: 'car', path: '/lots' },
    ...bo,
    { icon: 'user', path: '/profile/home' },
  ].map((link, i) => {
    const active = link.path === pathname;
    return <NavLink className="px-3 py-1" to={link.path} key={i}>
      <MDBIcon icon={link.icon} fal={!active} />
    </NavLink>;
  });
  return <div style={style} className="d-flex d-sm-none justify-content-around align-items-center">
    {links}
  </div>;
};

const Rules = () => {
  const [rules, setRules] = useState([]);
  useEffect(() => {
    loadRules();
  }, []);
  const loadRules = async () => {
    const { data } = await api.get('/outlet/rules', { params: { method: 'dictionary' } });
    setRules(data);
  };
  const preview = rules.map((rule, i) => {
    const body = rule.body.split(/\n/).map((line, j) => {
      if (line[0] === '-') {
        return <p key={j} className="ml-5">- {line.substring(1)}</p>;
      } else {
        return <p className="ml-3" key={j}>{line}</p>;

      }
    });
    return <div key={i}>
      <h3>{i + 1}. {l['rules_' + rule.header]}</h3>
      <div>
        {body}
      </div>
    </div>;
  });
  return <MDBContainer className="p-0">
    <h1>{l.rules}</h1>
    {preview}
  </MDBContainer>;
};

const About = () => {
  const { size } = useContext(SizeContext);
  const style = {
    backgroundImage: 'url(\'office_q1.jpg\')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: (size > 0 ? '0 -10vh' : ''),
    height: (size > 0 ? '70vh' : '100vh'),
    backgroundAttachment: 'fixed',
  };
  const opps = [
    'Основана в 2006 году.',
    'Опыт сотрудничества боле чем с 200 клиентами и управления более 12000 автомобилей',
    'Общий парк 6000 автомобилей',
    'Устойчивое финансовое положение и стабильность'
  ].map((o, i) => {
    return <h4 key={i} className="white-text  p-2" style={{ backgroundColor: 'rgba(0,0,0,.3)' }}>
      <MDBIcon icon="acorn" className="mr-2" />{o}
    </h4>;
  });
  return <>
    <div data-test="view" className="view pt-5 mb-5"
      style={style}>
      <MDBMask overlay="indigo-light" className="flex-center">
        <MDBContainer>
          <h1 className="white-text text-center h1">АВТО ПАРТНЕРС</h1>
          {opps}
        </MDBContainer>
      </MDBMask>
    </div>
    <MDBContainer>
      <p>Компания Auto Partners предоставляет широкий спектр решений по эффективному управлению автопарком клиента
        на всей территории Российской Федерации. В зависимости от актуальных потребностей клиента, мы рады предложить
        следующие пакеты сервисов:</p>
      <ul>
        <li>Операционную аренду автомобилей (аренда с полным сервисным обслуживанием)</li>
        <li>Возвратная аренда (выкуп существующего автопарка компании и последующая сдача в операционную аренду)</li>
        <li>Флит-менеджмент (управление корпоративными автопарками)</li>
        <li>Средне- и краткосрочная аренда автомобилей.</li>
      </ul>
      <p>Компания Auto Partners имеет 17-ти летний опыт успешной работы в сфере
        флит-аутсорсинга за пределами Российской Федерации. Российское подразделение
        было основано в 2006 году, и с тех пор клиентами Auto Partners стали многие
        крупные международные компании, которые оценивают нас как очень надежного партнера.</p>
      <p>Головной офис Auto Partners находится в Москве, полное покрытие территории
        страны осуществляется с помощью региональных подразделений компании,
        которые функционируют в 8 крупных городах России:</p>
      <ul>
        <li>Санкт-Петербург</li>
        <li>Нижний Новгород</li>
        <li>Самара</li>
        <li>Краснодар</li>
        <li>Екатеринбург</li>
        <li>Новосибирск</li>
        <li>Иркутск</li>
        <li>Владивосток</li>
      </ul>
      <p>Компания Auto Partners обладает серьезным опытом в сфере операционной аренды. В данный
        момент под управлением команды профессионалов Auto Partners находится более 6000 автомобилей в России.
        Концентрируясь на потребностях и задачах клиента, мы ответственно и внимательно относимся к каждому в нашем автопарке.</p>

      <p>Для получения более подробной информации о компании Auto Partners и о доступных услугах
        в рамках флит-аутсорсинга - пожалуйста, обращайтесь в отдел продаж Auto Partners по тел.
        +7(495)780-52-54 или sales@ap-ru.com</p>
    </MDBContainer>
  </>;
};