import React from 'react';
import Countdown from 'react-countdown-now';
import { useContext } from 'react';
import { LotsContext } from '../../../context';
import { moment } from '../../../../variables';
import l from '../../../../localization';

const Ctdwn = ({ started, lot, lot: { start_at, end_at } }) => {
  const { setLot } = useContext(LotsContext);
  const start = moment(start_at);
  const end = moment(end_at);
  const date = start.diff(moment()) > 0 ? start : end;
  const prepend = started ? l.startsIn : l.endsIn;

  const finished = () => {
    setTimeout(() => {
      if(started) {
        setLot({ ...lot, status: 'published' });
      } else {
        if(moment(lot.end_at).isBefore(moment())) setLot({ ...lot, status: 'finished' });
      }
    }, 200);
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if(completed) return l.ended;
    if(days > 0) return <span><span className="mr-1">{prepend}</span>{days} {l.daysShort}</span>;
    if(hours > 2) return <span><span className="mr-1">{prepend}</span>{hours} {l.hoursShort}</span>;

    return <span><span className="mr-1">{prepend}</span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
  };

  const zeroPad = (num, places=2) => String(num).padStart(places, '0');

  return <Countdown
    renderer={renderer}
    date={date.toDate()}
    onComplete={finished}
  />;
};

export default Ctdwn;