import axios from 'axios';
import moment from 'moment';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import 'moment/locale/ru';
import { IsTest, isLocalhost } from './serviceWorker';
import { useResource } from 'ap-utils';

registerLocale('ru', ru);
setDefaultLocale('ru');

const api = axios.create();
let authurl;
let wsurl;
let ermurl;
let cable;
let connecturl;

const initialize = async callback => {
  let path = await getApi();
  if (isLocalhost) {
    authurl = 'http://localhost:3005';
    wsurl = 'ws://localhost:3005/cable';
    path = 'http://localhost:3005/api/v4';
    connecturl = 'http://localhost:3006/';
  } else if (IsTest) {
    wsurl = 'wss://outlet.ap-ru.com/cable';
    path = 'https://auth-test.ap-ru.com/api/v4';
    connecturl = 'https://connect-test.ap-ru.com/';
    authurl = 'https://auth-test.ap-ru.com';
  }
  api.defaults.baseURL = path;
  useResource.configure({
    apiInstance: api,
    qParams: { arrayFormat: 'bracket' }
  });
  callback();
};

const getApi = async () => {
  let path = 'https://auth.ap-ru.com/api/v4/ping';
  try {
    const local = axios.get('https://a.ap-ru.com/api/v4/ping');
    const external = axios.get('https://auth.ap-ru.com/api/v4/ping');
    const { config: { url } } = await Promise.race([local, external]);
    path = url;
  } catch (error) {
    console.log(error, path);
  }
  authurl = path.replace('/api/v4/ping', '');
  connecturl = 'https://connect.ap-ru.com/';
  if (path.includes('auth.ap-ru.com')) {
    wsurl = 'wss://carsoutlet.ap-ru.com/cable';
    ermurl = 'https://erm.ap-ru.com';
    return 'https://auth.ap-ru.com/api/v4';
  } else if (path.includes('a.ap-ru.com')) {
    wsurl = 'wss://outlet.ap-ru.com/cable';
    ermurl = 'https://e.ap-ru.com';
    return 'https://a.ap-ru.com/api/v4';
  } else {
    ermurl = 'https://erm-test.ap-ru.com';
    wsurl = 'wss://auth-test.ap-ru.com/cable';
  }
};

const TOS = Array.from({ length: 15 }).reduce((res, _, i) => { res[i + 14] = i + 1; return res; }, {});
TOS[133] = 0;

moment.locale('ru');
moment.defaultFormat = 'DD.MM.YYYY HH:mm';

export { wsurl, ermurl, authurl, connecturl, api, TOS, moment, initialize, cable, isLocalhost };
