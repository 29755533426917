export const conditionsEn = '';
export const conditionsRu = `
Настоящим я, дееспособное лицо, Пользователь, в соответствии с требованиями
Федерального закона от 27.07.2006 г. №152-ФЗ «О персональных данных» действуя своей
волей и в своих интересах, выражаю ООО «АВТО ПАРТНЕРС» (ИНН 7705746577, ОГРН
5067746074094, 129090, г. Москва, Олимпийский проспект, 14, 129090) (далее –
Общество) свое согласие на обработку следующих категорий моих персональных данных:
1. Фамилия, имя, отчество.
2. Адрес электронной почты.
3. Номер телефона.
Обработка персональных данных - действия (операции), совершаемые с использованием
средств автоматизации или без использования таких средств с персональными данными,
включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
изменение), извлечение, использование, передачу (распространение, предоставление,
доступ), обезличивание, блокирование, удаление, уничтожение.
Целью обработки персональных данных являются соблюдение законодательства о
персональных данных и повышение эффективности ведения бизнеса, в частности:
1. для заключения и исполнения договоров купли-продажи между Пользователем и
Обществом.
2. для осуществления прав и законных интересов Общества и третьих лиц при условии,
что при этом не нарушаются мои права и свободы.
3. для осуществления прямых контактов между мной и Обществом в целях установления
и дальнейшего развития отношений.
4. для поддержания отношений между мной и Обществом.
Я даю согласие на передачу ООО «АВТО ПАРТНЕРС» моих персональных данных для
обработки третьим лицам, с которыми у Общества заключены соглашения о
конфиденциальности и неразглашении информации в вышеуказанных целях.
Данным Соглашением я подтверждаю достоверность сведений, предоставленных при
регистрации.
Настоящим даю свое согласие на то, что мои персональные данные будут храниться в
Обществе в течение предусмотренного законодательством Российской Федерации срока
хранения документов либо на период до отзыва мной согласия на обработку
персональных данных.
Согласие предоставляется:
ООО «АВТО ПАРТНЕРС» (ИНН 7705746577, ОГРН 5067746074094, г. Москва,
Олимпийский проспект, 14)
Согласие на обработку персональных данных считается полученным ООО «АВТО
ПАРТНЕРС» с момента выбора варианта «Зарегистрироваться», расположенного в конце
формы регистрации.
Я проинформирован, что настоящее Согласие может быть отозвано посредством
направления уведомления заказным письмом в адрес Общества по адресу: 129090, г.
Москва, Олимпийский проспект, 14.
`;